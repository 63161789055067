import Script from 'next/script'
import Head from 'next/head'
import { Footer, Header, Oops } from '../js/components'
import { OrganizationSchema, WebPageSchema, BreadcrumbListSchema } from '../js/schemas'
import path from 'path'
import fs from 'fs/promises' // or 'fs' if using callbacks

export async function getStaticProps({ locale, locales }) {
  const host =
    process.env.NODE_ENV === 'development' ? process.env.LOCALHOST : process.env.CHARMELEON //"http://localhost:8080/"
  let content
  try {
    const filePath = path.join(process.cwd(), 'public', 'content', locale, 'homepage.json')

    // Read the file
    const jsonData = await fs.readFile(filePath, 'utf8')
    content = JSON.parse(jsonData)
  } catch (error) {
    console.error('error', error)
  }
  return {
    props: { content, locale, locales },
  }
}

export default function Custom404({ content, locale, locales }) {
  return (
    <div className="errorpage">
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-166939348-2"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'UA-166939348-2');
                `}
      </Script>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NRW96HD');
                `}
      </Script>
      <Script id="hotjar" strategy="afterInteractive">
        {`
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:2080824,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
      </Script>
      <OrganizationSchema />
      <WebPageSchema name={content.oops404.title} description={content.oops404.description} />
      <BreadcrumbListSchema
        items={[
          {
            name: 'Home',
            url: `https://edworking.com${locale !== 'en' ? `/${locale}` : ''}`,
          },
          {
            name: '404',
            url: `https://edworking.com/${locale !== 'en' ? `${locale}/` : ''}404`,
          },
        ]}
      />
      <Head>
        <title>{content.oops404.title}</title>
        <meta itemProp="name" content={content.oops404.title} />
        <meta itemProp="image" content={'/images/EdworkingPreview.png'} />
        <meta itemProp="description" content={content.oops404.description} />
        <meta name="description" content={content.oops404.description} />
        <meta property="og:title" content={content.oops404.title} />
        <meta property="og:description" content={content.oops404.description} />
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <meta itemProp="image" content={'/images/EdworkingPreview.png'} />
        <meta property="og:image" content={'/images/EdworkingPreview.png'} />
        <meta property="og:image" content={'/images/EdworkingPreview.png'} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image" content={'/images/EdworkingPreview.png'} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="150" />
        {locales.map((locale1, index) => (
          <link
            key={index}
            rel="alternate"
            hrefLang={locale1}
            href={`https://edworking.com${locale1 !== 'en' ? `/${locale1}` : ''}/404`}
          />
        ))}
        <link rel="alternate" href={`https://edworking.com/404`} hrefLang="x-default" />
        <link
          rel="canonical"
          href={`https://edworking.com/${locale !== 'en' ? `${locale}/` : ''}404`}
          key="canonical"
        />
      </Head>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NRW96HD" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      <Header clean content={content.header} />
      <Oops content={content.oops404} />
      <Footer content={content.footer} />
    </div>
  )
}
